import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import organizationProp from '../../../../commonComponents/propTypes/organizationProp';
import LanguageInput from '../../../../commonComponents/forms/inputs/LanguageInput';
import { uuid } from '../../../../services/utilityFunctions';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';
import CloseIcon from '../../../../commonComponents/icon/CloseIcon';

function LanguageForm(props) {
  const {
    t,
    id,
    organizationLanguages,
    onChange,
    defaultLanguage,
    lostReason,
  } = props;
  return organizationLanguages.map(language => {
    const isDefaultLanguage =
      defaultLanguage?.toLowerCase() === language?.code?.toLowerCase();
    const label = !isDefaultLanguage
      ? language.name
      : `${t('name')} (${language.name})`;
    const value = lostReason?.translations?.[language.code];

    return (
      <div className="lost-reason">
        <LanguageInput
          key={language?.code}
          id={`${id}-${language?.code}`}
          label={label}
          language={language}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  });
}

function TabLostReasons(props) {
  const { t, organization, organizationLanguages } = props;
  const { defaultLanguage = 'en', settings = {} } = organization || {};
  const { lostReasons: lostReasonsOrg = [] } = settings?.opportunities || {};
  const [lostReasons, setLostReasons] = useState(
    lostReasonsOrg?.map(l => ({ ...l, _id: uuid() })),
  );

  const handleChange = useCallback(
    (lostReasonId, field, value, ISOCode) => {
      const isDefaultLanguage =
        defaultLanguage?.toLowerCase() === ISOCode?.toLowerCase();

      setLostReasons(prevLostReasons =>
        prevLostReasons?.map(lostReason => {
          if (lostReason?._id === lostReasonId) {
            // Create a shallow copy of the lostReason object and its translations to ensure immutability
            const updatedTranslations = {
              ...lostReason.translations,
              [ISOCode]: value,
            };

            return {
              ...lostReason,
              translations: updatedTranslations,
              name: isDefaultLanguage ? value : lostReason.name,
            };
          }

          return lostReason;
        }),
      );
    },
    [setLostReasons, defaultLanguage],
  );

  const handleAddLostReason = useCallback(() => {
    setLostReasons(prevState => {
      const translations = {};
      organizationLanguages.forEach(l => {
        translations[l.code] = '';
      });
      return prevState.concat({
        _id: uuid(),
        name: '',
        translations,
        position: prevState.length + 1,
      });
    });
  }, [setLostReasons, organizationLanguages]);

  const handleRemoveReason = useCallback(
    reasonId => {
      setLostReasons(prevState =>
        prevState?.filter(reason => reason?._id !== reasonId),
      );
    },
    [setLostReasons],
  );

  return (
    <section id="tabLostReasons">
      <input
        type="hidden"
        name="lostReasons"
        id="lostReasons"
        value={JSON.stringify(lostReasons)}
      />
      <h4 className="title">{t('opportunity.lostReasons')}</h4>
      {lostReasons.map(lostReason => (
        <div key={lostReason?._id} className="lost-reason-container">
          <div className="lost-reason-translation">
            <LanguageForm
              t={t}
              id={lostReason?._id}
              onChange={(id, code, value) =>
                handleChange(lostReason?._id, 'translations', value, code)
              }
              organizationLanguages={organizationLanguages}
              defaultLanguage={defaultLanguage}
              lostReason={lostReason}
            />
          </div>
          <button
            type="button"
            onClick={() => handleRemoveReason(lostReason?._id)}
            className="remove-reason"
          >
            <CloseIcon color="#FFFFFF" />
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddLostReason}
        className="btn btn-dynamic add-reason"
      >
        {t('opportunity.add.lostReason')}
      </button>
    </section>
  );
}

TabLostReasons.propTypes = {
  t: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
  organizationLanguages: PropTypes.arrayOf(
    PropTypes.shape(CountryProp.propType),
  ).isRequired,
};
TabLostReasons.defaultProps = {};

export default translate(TabLostReasons);
