import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import organizationProp from '../../commonComponents/propTypes/organizationProp';
import i18n from '../../services/i18n';
import TabLostReasons from '../../sections/settings/configurations/modules/TabLostReasons';

const TabCustomLostReasonsWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const { t, organization } = props;

  useImperativeHandle(ref, () => ({}));

  return (
    <>
      <TabLostReasons
        {...childProps}
        // onChange={handleChanges}
        organization={organization}
        t={i18n.__}
      />
    </>
  );
});

TabCustomLostReasonsWebHandler.propTypes = {
  t: PropTypes.func.isRequired,
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
};

window.TabLostReasons = class TabLostReasonsRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'TabLostReasons container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <TabCustomLostReasonsWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
