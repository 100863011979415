/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function CloseIcon({ width, height, color }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.324 3.93931L13.2641 9.99986L19.324 16.0601C20.2253 16.9619 20.2253 18.4225 19.324 19.3243C18.8736 19.7746 18.2831 20 17.6929 20C17.1018 20 16.5113 19.775 16.0612 19.3243L10 13.2633L3.93929 19.3242C3.48897 19.7746 2.89844 19.9999 2.30775 19.9999C1.71722 19.9999 1.1271 19.7749 0.676378 19.3242C-0.225 18.4229 -0.225 16.9621 0.676378 16.0601L6.73609 9.99981L0.676033 3.93931C-0.225344 3.03793 -0.225344 1.5769 0.676033 0.675521C1.57724 -0.225174 3.03739 -0.225174 3.93894 0.675521L9.99997 6.73607L16.0605 0.675521C16.9623 -0.225174 18.4226 -0.225174 19.3236 0.675521C20.2253 1.5769 20.2253 3.03793 19.324 3.93931Z" fill={color} />
    </svg>
  );
}

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

CloseIcon.defaultProps = {
  width: 19,
  height: 19,
  color: "#6F6C80",
};
